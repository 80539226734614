const DocMenuConfig = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods'],
      },
      {
        sectionTitle: "user_management",
        route: "/user",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        roles: ['admin'],
        sub: [
          {
            heading: "members",
            route: "/user_members",
            roles: ['admin']
          },
          {
            heading: "pocket_items",
            route: "/user_pocket_items",
            roles: ['admin']
          }
        ]
      },
      {
        sectionTitle: "shipping_management",
        route: "/shipping",
        svgIcon: "media/icons/duotune/ecommerce/ecm006.svg",
        fontIcon: "bi-truck",
        roles: ['admin', 'goods'],
        sub: [
          // {
          //   heading: "shipping_orders",
          //   route: "/shipping_orders",
          //   roles: ['admin', 'goods']
          // },
          {
            heading: "shipping_products",
            route: "/shipping_products",
            roles: ['admin', 'goods']
          },
          {
            heading: "shipping_records",
            route: "/shipping_records",
            roles: ['admin', 'goods']
          }
        ]
      },
      {
        heading: "card_records",
        route: "/card_records",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods']
      },
      {
        heading: "box_records",
        route: "/box_records",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods']
      },
      {
        heading: "gift_records",
        route: "/gift_records",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods']
      },
      {
        heading: "water_items",
        route: "/water_items",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods']
      },
      {
        heading: "loser_items",
        route: "/loser_items",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods']
      },
      {
        heading: "product_brands",
        route: "/product_brands",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods']
      },
      {
        heading: "ips",
        route: "/ips",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods']
      },
      {
        heading: "compounds",
        route: "/compounds",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods']
      },
      {
        heading: "payment_records",
        route: "/payment_records",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin']
      },
      {
        heading: "add_balance_records",
        route: "/add_balance_records",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin']
      },
      {
        heading: "add_point_records",
        route: "/add_point_records",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin']
      },
      {
        heading: "products",
        route: "/products",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods']
      },
      {
        heading: "events",
        route: "/events",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods']
      },
      {
        heading: "box_categories",
        route: "/box_categories",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods']
      },
      {
        heading: "box_infos",
        route: "/box_infos",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods']
      },
      {
        heading: "banners",
        route: "/banners",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin', 'operation', 'goods']
      },
      {
        heading: "admins",
        route: "/admins",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin']
      },
      {
        heading: "distributions",
        route: "/distributions",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        roles: ['admin']
      },
    ],
  },
];

export default DocMenuConfig;
