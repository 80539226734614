
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "pocket-items",
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const product_name = ref("");
    const mobile = ref("");
    const status = ref("pending");
    const items = ref([]);
    
    const loading = computed(() => store.state.PocketItemModule.loading);
    
    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0
    });

    const loadData = () => {
      store.dispatch(Actions.FETCH_POCKET_ITEMS, {
        currentPage: page.value.currentPage,
        pageSize: page.value.pageSize,
        product_name: product_name.value,
        mobile: mobile.value,
        status: status.value
      }).then((response) => {
        items.value = response.data.list;
        page.value.totalResult = response.data.total;
      });
    };

    const handleSearch = () => {
      page.value.currentPage = 1;
      loadData();
    };

    const onPageChange = (evt: any) => {
      const { type, currentPage, pageSize } = evt;
      
      if (type === 'size') {
        page.value.currentPage = 1;
      } else {
        page.value.currentPage = currentPage;
      }
      
      page.value.pageSize = pageSize;
      loadData();
    };

    const getStatusClass = (status: string) => {
      switch (status) {
        case 'pending':
          return 'badge badge-light-warning';
        case 'waiting_for_deliver':
          return 'badge badge-light-info';
        case 'recycle':
          return 'badge badge-light-success';
        case 'opened':
          return 'badge badge-light-primary';
        case 'delivered':
          return 'badge badge-light-success';
        case 'abandoned':
          return 'badge badge-light-danger';
        case 'compounded':
          return 'badge badge-light-info';
        default:
          return 'badge badge-light-secondary';
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("用户背包列表", ["用户管理"]);
    });

    return {
      product_name,
      mobile,
      status,
      items,
      page,
      loading,
      handleSearch,
      onPageChange,
      getStatusClass
    };
  }
});
