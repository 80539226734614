import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface PocketItem {
  user_nickname: string;
  user_mobile: string;
  product_name: string;
  product_recycle_price: number;
  status: string;
  count: number;
  created_at: string;
}

@Module
export default class PocketItemModule extends VuexModule {
  pocketItems: Array<PocketItem> = [];
  pocketItemCount = 0;
  loading = false;

  get currentPocketItems() {
    return this.pocketItems;
  }

  get currentPocketItemCount() {
    return this.pocketItemCount;
  }

  @Mutation
  [Mutations.SET_POCKET_ITEMS](pocketItems: Array<PocketItem>) {
    this.pocketItems = pocketItems;
  }

  @Mutation
  [Mutations.SET_POCKET_ITEM_COUNT](count: number) {
    this.pocketItemCount = count;
  }

  @Mutation
  [Mutations.SET_POCKET_ITEMS_LOADING](loading: boolean) {
    this.loading = loading;
  }

  @Action
  [Actions.FETCH_POCKET_ITEMS](payload) {
    this.context.commit(Mutations.SET_POCKET_ITEMS_LOADING, true);
    return new Promise<void>((resolve, reject) => {
      ApiService.query("admin/users/pocket_items_list", {
        params: {
          currentPage: payload.currentPage,
          pageSize: payload.pageSize,
          product_name: payload.product_name,
          mobile: payload.mobile,
          status: payload.status
        }
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_POCKET_ITEMS, data.data.list);
          this.context.commit(Mutations.SET_POCKET_ITEM_COUNT, data.data.total);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          this.context.commit(Mutations.SET_POCKET_ITEMS_LOADING, false);
        });
    });
  }
} 