import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import BusinessDistrictModule from "@/store/modules/BusinessDistrictModule";
import ShopModule from "@/store/modules/ShopModule";
import BudgetModule from "@/store/modules/BudgetModule";
import AdminModule from "@/store/modules/AdminModule";
import UserModule from "@/store/modules/UserModule";
import OrderModule from "@/store/modules/OrderModule";
import RoleModule from "@/store/modules/RoleModule";
import EventModule from "@/store/modules/EventModule";
import CouponModule from "@/store/modules/CouponModule";
import SystemSettingModule from "@/store/modules/SystemSettingModule";
import NewsItemModule from "@/store/modules/NewsItemModule";

import ProductBrandModule from "@/store/modules/ProductBrandModule"
import ProductModule from "@/store/modules/ProductModule"
import BoxCategoryModule from "@/store/modules/BoxCategoryModule"
import BoxInfoModule from "@/store/modules/BoxInfoModule"
import MemberModule from "@/store/modules/MemberModule"
import CompoundModule from "@/store/modules/CompoundModule"
import IpModule from "@/store/modules/IpModule"
import BannerModule from "@/store/modules/BannerModule"
import RemoveBalanceRecordModule from "@/store/modules/RemoveBalanceRecordModule"
import PaymentRecordModule from "@/store/modules/PaymentRecordModule"
import AddBalanceRecordModule from "@/store/modules/AddBalanceRecordModule"
import AddPointRecordModule from "@/store/modules/AddPointRecordModule"
import AddExtraPointRecordModule from "@/store/modules/AddExtraPointRecordModule"
import ShippingOrderModule from "@/store/modules/ShippingOrderModule"
import LevelModule from "@/store/modules/LevelModule"
import GiftRecordModule from "@/store/modules/GiftRecordModule"
import DistributionModule from "@/store/modules/DistributionModule"
import ShippingProductModule from "@/store/modules/ShippingProductModule"
import PocketItemModule from "@/store/modules/PocketItemModule"
import ShippingRecordModule from "@/store/modules/ShippingRecordModule"

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    BusinessDistrictModule,
    ShopModule,
    BudgetModule,
    AdminModule,
    DistributionModule,
    UserModule,
    RoleModule,
    CouponModule,
    OrderModule,
    EventModule,
    SystemSettingModule,
    NewsItemModule,

    ProductBrandModule,
    ProductModule,
    BoxInfoModule,
    BoxCategoryModule,
    MemberModule,
    CompoundModule,
    IpModule,
    BannerModule,
    RemoveBalanceRecordModule,
    PaymentRecordModule,
    AddBalanceRecordModule,
    AddPointRecordModule,
    AddExtraPointRecordModule,
    ShippingOrderModule,
    LevelModule,
    GiftRecordModule,
    ShippingProductModule,
    PocketItemModule,
    ShippingRecordModule,
  }
})

export default store
