import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Compound {
  id: number;
  product_name: string;
  product_id: number;
  price: number;
  sorting: number;
  enabled: boolean;
  compound_qty: number;
  stock: number;
  water_need: number;
  daily_limit: number;
}

export interface CompoundInfo {
  compounds: Array<Compound>;
  compound: Compound;
}

@Module
export default class CompoundModule
  extends VuexModule
  implements CompoundInfo
{
  compounds = [] as Array<Compound>;
  compound_count = 0;
  compound = {} as Compound;

  get currentCompound(): Compound {
    return this.compound;
  }

  get currentCompounds(): Array<Compound> {
    return this.compounds;
  }

  get currentCompoundCount() {
    return this.compound_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_COMPOUND](compound) {
    this.compound = compound
  }

  @Mutation
  [Mutations.SET_COMPOUNDS](compounds) {
    this.compounds = compounds
  }

  @Mutation
  [Mutations.SET_COMPOUND_COUNT](compound_count) {
    this.compound_count = compound_count
  }

  @Action
  [Actions.GET_COMPOUNDS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/compounds", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_COMPOUNDS,
            data["compounds"]
          );
          this.context.commit(
            Mutations.SET_COMPOUND_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_COMPOUND](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/compounds/${id}`)
        .then(({ data }) => {
          console.log(data["compound"])
          this.context.commit(
            Mutations.SET_COMPOUND,
            data["compound"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_COMPOUND](credentials) {
    ApiService.setHeader()

    const params = {
      compound: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/compounds`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_COMPOUND,
            data["compound"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_COMPOUND](credentials) {
    ApiService.setHeader()

    const params = {
      compound: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/compounds/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_COMPOUND,
            data["compound"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_COMPOUND](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/compounds/${id}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
