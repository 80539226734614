
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";
import { Compound } from "@/store/modules/CompoundModule"
import NewBoxItemInfoModal from "@/components/modals/forms/NewBoxItemInfoModal.vue"

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  components: {
    NewBoxItemInfoModal
  },
  setup() {
    onMounted(() => {
      if (!route.params.id) {
        setCurrentPageBreadcrumbs("合成新增", ["合成管理;compounds"])
      } else {
        setCurrentPageBreadcrumbs("合成编辑", ["合成管理;compounds", route.params.id.toString()])
      }
    });

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    });

    const formRef = ref<FormInstance>();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const compound = ref({
      id: 0,
      product_name: '请选择商品',
      product_id: 0,
      price: 0,
      sorting: 0,
      enabled: false,
      compound_qty: 0,
      stock: 0,
      water_need: 0,
      daily_limit: 0
    } as Compound)

    const onCancel = () => {
      router.push({ name: "compounds" });
    };

    const isNew = !route.params.id;

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          if (isNew) {
            store
              .dispatch(
                Actions.CREATE_COMPOUND,
                compound.value
              )
              .then(() => {
                router.push({ name: "compounds" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          } else {
            store
              .dispatch(Actions.UPDATE_COMPOUND, {
                id: route.params.id,
                values: compound.value,
              })
              .then(() => {
                router.push({ name: "compounds" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          }
        } else {
          return false;
        }
      });
    };

    if (!isNew) {
      store
        .dispatch(Actions.GET_COMPOUND, route.params.id)
        .then(() => {
          compound.value = store.getters.currentCompound
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const add_box_item_infos = (products) => {
      console.log(i)

      if (products[0]) {
        var i = products[0]
        console.log(i)
        compound.value.product_id = i.id
        compound.value.product_name = i.brand + "_" + i.ip + "_" + i.name
        compound.value.price = i.recycle_price
      }
    }

    return {
      onSubmit,
      compound,
      rules,
      onCancel,
      formRef,
      add_box_item_infos,
      isNew
    };
  },
});
